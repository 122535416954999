import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

/* Layout */
import Layout from "@/layout";

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export var constantRoutes = [
//   {
//   path: '/publicitypage',
//   component: () => import('@/views/publicitypage/index'),
//   hidden: true
// },
{
  path: "/login",
  component: function component() {
    return import("@/views/login/index");
  },
  hidden: true
},
// {
//   path: "/register",
//   component: () => import("@/views/login/register"),
//   hidden: true,
// },

{
  path: "/404",
  component: function component() {
    return import("@/views/404");
  },
  hidden: true
},
// {
//   path: "/transition",
//   component: () => import("@/views/transition"),
//   hidden: true,
// },
{
  path: "/",
  hidden: true,
  redirect: "/docReview",
  meta: {}
}, {
  path: "/writing",
  component: Layout,
  redirect: "/docReview/writing/index",
  children: [{
    component: function component() {
      return import("@/views/writing/index");
    },
    hidden: true,
    path: "/docReview/writing",
    name: "Writing",
    meta: {
      title: "文档审核",
      icon: "shouye-icon-1",
      affix: true
    }
  }]
},
// {
//   path: '/officialWriting',
//   component: Layout,
//   redirect: '/intelligentWriting/officialWriting/index',
//   children: [{
//     component: () => import('@/views/officialWriting/index'),
//     hidden: true,
//     path: '/intelligentWriting/officialWriting',
//     name: 'OfficialWriting',
//     meta: {
//       affix: true
//     }
//   }]
// },

{
  path: "/docReview",
  component: Layout,
  redirect: "/docReview/index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/docReview/index");
    },
    name: "DocReview",
    meta: {
      title: "文档审校",
      icon: "wendangshenge",
      noCache: true
    }
  }, {
    path: "demo",
    component: function component() {
      return import("@/views/demo/index");
    },
    hidden: true,
    name: "CorrectDemo",
    meta: {
      title: "文档审校",
      icon: "wendangshenge",
      noCache: true
    }
  }, {
    path: "test",
    component: function component() {
      return import("@/views/demo/test");
    },
    hidden: true,
    name: "Test",
    meta: {
      title: "测试类",
      icon: "wendangshenge",
      noCache: true
    }
  }]
}, {
  path: "/intelligentWriting",
  component: Layout,
  redirect: "/intelligentWriting/index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/officialWriting/index");
    },
    name: "IntelligentWriting",
    hidden: APP_ROUTER.isIntelligentWriting,
    meta: {
      title: "智能写作",
      icon: "zhinengxiezuo",
      noCache: true
    }
  }]
},
// {
//   path: '/intelligentWriting',
//   component: Layout,
//   redirect: '/intelligentWriting/index',
//   children: [{
//     path: 'index',
//     // /intelligentWriting/officialWriting
//     component: () => import('@/views/intelligentWriting/index'),
//     name: 'IntelligentWriting',
//     hidden: APP_ROUTER.isIntelligentWriting,
//     meta: {
//       title: '智能写作',
//       icon: 'zhinengxiezuo',
//       noCache: true
//     }
//   }]
// },
{
  path: "/historyDocs",
  component: Layout,
  redirect: "/docReview/historyDocs/index",
  children: [{
    path: "/docReview/historyDocs",
    component: function component() {
      return import("@/views/historyDocs/index");
    },
    name: "historyDocs",
    hidden: true,
    meta: {
      title: "历史文档",
      icon: "lishi",
      noCache: true
    }
  }, {
    path: "official",
    component: function component() {
      return import("@/views/historyDocs/official");
    },
    name: "official",
    hidden: true,
    meta: {
      title: "我的公文",
      icon: "zhinengxiezuo",
      activeMenu: "/intelligentWriting/index"
    }
  }]
},
// {
//   path: "/trace",
//   component: Layout,
//   redirect: '/trace/index',
//   children: [{
//     path: 'index',
//     component: () => import("@/views/trace/index"),
//     name: 'trace',
//     meta: {
//       title: '审校痕迹',
//       icon: 'shenhe',
//       noCache: true
//     }
//   }]
// },
{
  path: "/reviseRule",
  component: Layout,
  redirect: "/reviseRule/index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/reviseRule/index");
    },
    name: "reviseRule",
    meta: {
      title: "审校规则",
      icon: "rule",
      noCache: true
    }
  }]
}, {
  path: "/material",
  component: Layout,
  redirect: "/material/index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/material/index");
    },
    name: "material",
    meta: {
      title: "素 材 库",
      icon: "sucaiku",
      noCache: true
    }
  }]
}, {
  path: "/materialList",
  component: Layout,
  redirect: "/materialList/index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/material/list");
    },
    name: "materialList",
    meta: {
      activeMenu: "/material/index",
      icon: "sucaiku"
    },
    hidden: true
  }]
}, {
  path: "/search",
  component: Layout,
  redirect: "/search/index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/search/index");
    },
    name: "search",
    meta: {
      activeMenu: "/material/index",
      icon: "sucaiku"
    },
    hidden: true
  }]
}, {
  path: "/myProfile",
  component: Layout,
  redirect: "/myProfile/index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/myProfile/index");
    },
    name: "myProfile",
    meta: {
      title: "我的资料",
      icon: "wodeziliao",
      noCache: true
    }
  }]
}, {
  path: "/toolbox",
  component: Layout,
  redirect: "/toolbox/index",
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/toolbox/index");
    },
    name: "toolbox",
    meta: {
      title: "工具箱",
      icon: "toolbox",
      noCache: true
    }
  }, {
    path: "transform",
    component: function component() {
      return import("@/views/toolbox/transform/index");
    },
    name: "transform",
    meta: {
      title: "文档转换",
      noCache: true
    },
    hidden: true
  }, {
    path: "record",
    component: function component() {
      return import("@/views/toolbox/record/index");
    },
    name: "record",
    meta: {
      title: "语音识别",
      noCache: true
    },
    hidden: true
  }, {
    path: "history",
    component: function component() {
      return import("@/views/toolbox/history/index");
    },
    name: "history",
    meta: {
      title: "历史记录",
      noCache: true
    },
    hidden: true
  }, {
    path: "detail",
    component: function component() {
      return import("@/views/toolbox/detail");
    },
    name: "detail",
    meta: {
      title: "录音详情",
      noCache: true
    },
    hidden: true
  }]
}, {
  path: "/personal",
  component: Layout,
  redirect: "/personal/index",
  hidden: true,
  children: [{
    path: "index",
    component: function component() {
      return import("@/views/personal/index");
    },
    name: "Personal",
    meta: {
      title: "个人中心",
      icon: "",
      noCache: true
    }
  }]
}, {
  path: "/dataParticulars",
  component: function component() {
    return import("@/views/dataParticulars/index");
  }
},
// {
//   path: "/userGuide",
//   component: () => import("@/views/userGuide/index"),
// },

// 404 page must be placed at the end !!!
{
  path: "*",
  redirect: "/404",
  hidden: true
}];
var createRouter = function createRouter() {
  return new Router({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;