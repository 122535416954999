import axios from "axios";
import { MessageBox, Message } from "element-ui";
import store from "@/store";
import router from "@/router";
import { getToken } from "@/utils/auth";

// create an axios instance
var service = axios.create({
  baseURL: "/fy-revise",
  // url = base url + request url  process.env.VUE_APP_BASE_API
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 300000 // request timeout
});
service.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded";
service.defaults.headers.post["is-ajax-request"] = "true";
service.defaults.headers.post["client-name"] = casClientName;
var href = window.location.href;
if (href.length > 1000) {
  href = window.location.origin + "/#/";
}
service.defaults.headers["redirect-url"] = href;
// service.defaults.headers.post["is-ajax-request"] =
// "true";
service.interceptors.request.use(function (config) {
  // do something before request is sent

  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers["X-Token"] = getToken();
  }
  return config;
}, function (error) {
  // do something with request error
  // console.log(error); // for debug
  return Promise.reject(error);
});

// response interceptor
service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
 */

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  //	var errorCode = res.data.errorCode;
  // if the custom code is not 20000, it is judged as an error.
  if (casEnabled && response.status === 401) {
    window.location.href = response.headers.location;
    return true;
  }
  if (!res.success && !(res.errorCode === "FY-V024" || res.errorCode === "FY-V026" || res.errorCode === "FY-V027")) {
    Message.closeAll();
    if (res.errorCode !== "FY-V004" && !response.config.url.includes("api/revise/queryPage")) {
      Message({
        message: res.errorDesc || "Error",
        type: "error",
        duration: 5 * 1000
      });
    }

    // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // if (res.errorCode === 'FY-V004') {
    //   // router.push({
    //   //   path: "/404"
    //   // });
    //   router.push({
    //     path: '/transition'
    //   })
    //   return res
    // }

    if (res.errorCode === "CAT-V004" || res.errorCode === "CAT-V003" || res.errorCode === "CAT-U011" || res.errorCode === "FY-V004") {
      router.push({
        path: "/login"
      });
    }
    return res;
  } else {
    return res;
  }
}, function (error) {
  console.log(error);
  if (error.message === "timeout of 15000ms exceeded") {
    return error;
  }
  if (error.response.status === 401) {
    localStorage.setItem("loginInfo", "");
    window.location.href = error.response.headers.location;
  } else if (error.response.status === 404) {
    router.push({
      path: "/404"
    });
  } else {
    Message.closeAll();
    Message({
      message: error.message,
      type: "error",
      duration: 5 * 1000
    });
  }
  return error;
});
export default service;